var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4f9e4899ce2cf4305f9e82209773e7cd6e18621a"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  ignoreErrors: [
    '/^Failed to fetch$/',
    /^Failed to fetch$/,
    /^Load failed$/,
    /^Illegal invocation$/,
    /did not match\. Server/,
    /^Failed to execute 'createObjectStore' on 'IDBDatabase'/,
    /^Failed to execute 'index' on 'IDBObjectStore'/,
    /Invalid type: 'container' must be a String or HTMLElement\./,
    /Unexpected token '<'/,
    /The operation is insecure\./,
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^https:\/\/www\.google\.fr/i,
    /^https:\/\/stats\.g\.doubleclick\.net/i,
    /^https:\/\/www\.google\.fr/i,
    /^https:\/\/connect\.facebook\.net/i,
  ],
  beforeSend: (event) => {
    /*
     * Vérifier les solutions sur https://github.com/vercel/next.js/issues/43088
     */
    if (
      event?.exception?.values?.[0]?.value?.includes(
        'Invariant: attempted to hard navigate to the same URL',
      )
    ) {
      return null;
    }
    return event;
  },
});
